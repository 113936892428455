<template>
  <v-layout column v-if="old">
    <LabelTextField label="wiek" readonly :value="old" disabled>
    </LabelTextField>
  </v-layout>
</template>

<script>
import moment from "moment";

export default {
  props: {
    patient: {},
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    LabelTextField: () => import("@/components/LabelTextField"),
  },
  computed: {
    old() {
      const possibilities = [
        ["years", "rok", "lata", "lat"],
        ["months", "miesiąc", "miesiące", "miesięcy"],
        ["weeks", "tydzień", "tygodnie", "tygodni"],
        ["days", "dzień", "dni", "dni"],
      ];

      for (let index = 0; index < possibilities.length; index++) {
        const range = possibilities[index];

        const res = this.convertPeriodToWords(...range);

        if (res === -1) {
          return null;
        }

        if (res !== 0) {
          return res;
        }
      }

      return null;
    },
  },
  methods: {
    convertPeriodToWords(period, single, multiSingle, plural) {
      if (this.patient.birth_date) {
        const diff = moment().diff(this.patient.birth_date, period);

        if (diff < 0) {
          return -1;
        }

        if (diff === 0) {
          return 0;
        }

        if (diff === 1) {
          return `1 ${single}`;
        }

        const lastDigit = parseInt(diff.toString().slice(-1));
        if (lastDigit < 5 && lastDigit > 1) {
          return `${diff} ${multiSingle}`;
        } else {
          return `${diff} ${plural}`;
        }
      }
      return -1;
    },
  },
};
</script>

<style scoped>
.fake-details {
  height: 23px;
  width: 100%;
}
</style>